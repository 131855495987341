import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import useTransactions from '@/hooks/transactions/useTransactions';

import TotalBalanceCard from '../components/cards/TotalBalanceCard';
import Loading from '../components/utility/Loading';
import StatusBadges from '../components/utility/statuses/StatusBadges';
import TransactionType from '../components/utility/statuses/TransactionType';
import { dateTimeFormatter } from '../formatters/DateTimeFormatter';
import { TransactionItemInterface } from '../models/TransactionInterface';

export default function TotalBalancePage() {
  document.title = 'Total balance';
  const [allTransactions, setAllTransactions] = useState<TransactionItemInterface[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const [contToken, setContToken] = useState<string>('');
  const [loadMoreToken, setLoadMoreToken] = useState<string>('');

  const { transactions, totalTransactions, transactionsContToken, transactionsIsLoading } = useTransactions({
    status: '',
    pageSize: 20,
    contToken: loadMoreToken,
  });

  const columns: string[] = [
    '#',
    'Transaction',
    'Amount',
    'Date/Time',
    'Track Info',
    'Playlist',
    'Accepted/Rejected',
    'Status',
  ];

  const navigate = useNavigate();
  const ref = useRef(null);

  useEffect(() => {
    if (!transactions || !totalTransactions) return;
    setAllTransactions((allTransactions) => {
      if (allTransactions.length === totalTransactions) {
        return [...allTransactions];
      } else {
        return [...allTransactions, ...transactions];
      }
    });
    setIsInitialLoad(false);
    setIsLoadingMore(false);
  }, [totalTransactions, transactions]);

  useEffect(() => {
    if (transactionsContToken) {
      setContToken(transactionsContToken);
    }
  }, [transactionsContToken]);

  const canPage = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (
        !totalTransactions ||
        isLoadingMore ||
        (entries[0].target as HTMLTableRowElement).rowIndex < allTransactions.length
      )
        return false;
      return entries[0].isIntersecting && !isLoadingMore && allTransactions.length < totalTransactions;
    },
    [totalTransactions, isLoadingMore, allTransactions.length]
  );

  const handleScroll = useCallback(() => {
    if (transactions && transactions.length > 0) {
      const observer = new IntersectionObserver((entries) => {
        if (!totalTransactions) return;
        if (canPage(entries) && allTransactions.length < totalTransactions) {
          setIsLoadingMore(true);
          setLoadMoreToken(contToken);
        } else {
          setIsLoadingMore(false);
        }
      }, {});
      if (ref.current) {
        observer.observe(ref.current);
      }
    }
  }, [transactions, totalTransactions, canPage, allTransactions.length, contToken]);

  useEffect(() => {
    handleScroll();
  }, [handleScroll]);

  return (
    <div className="page-content">
      <p
        className="text-faded cursor-pointer d-flex"
        onClick={() => {
          navigate('/dashboard');
        }}
      >
        <Icon>chevron_left</Icon>
        <span className="pt2">Dashboard</span>
      </p>
      <h2>Total balance</h2>
      <p className="text-faded">
        You can review all of your pending pitches below and decide whether you want to either accept or reject them
        onto your playlist.
      </p>
      <div className="d-flex mt16" data-testid="total-progress-card">
        <TotalBalanceCard />
      </div>

      {allTransactions.length > 0 && (
        <div className="playlist-card mt16">
          <div className="d-flex">
            <h3>Transaction History</h3>
            {totalTransactions && <p className="pt6 ml-auto text-faded">{totalTransactions} transactions in total</p>}
          </div>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <colgroup>
                <col width="1%" />
                <col width="8%" />
                <col width="2%" />
                <col width="8%" />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allTransactions.length > 0 &&
                  allTransactions?.map((row: TransactionItemInterface, index: number) => (
                    <TableRow
                      key={index}
                      ref={index === allTransactions.length - 1 ? ref : null}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '--animation-number': index % 20,
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell className="pt16 pb16">
                        <TransactionType status={row?.type} />
                      </TableCell>
                      <TableCell>£{row?.amount?.toFixed(2)}</TableCell>
                      <TableCell>{dateTimeFormatter(row?.updatedAt)}</TableCell>
                      <TableCell>
                        <p className="small p0">{row?.metadata?.pitchMetadata?.trackName}</p>
                        <p className="small text-faded p0">{row?.metadata?.pitchMetadata?.artistName}</p>
                      </TableCell>
                      <TableCell>{row?.metadata?.pitchMetadata?.playlistName}</TableCell>

                      <TableCell>
                        {row?.metadata?.pitchMetadata?.decision && (
                          <div>
                            <StatusBadges
                              status={row?.metadata?.pitchMetadata?.decision === 2 ? 'rejected' : 'accepted'}
                            ></StatusBadges>
                          </div>
                        )}
                      </TableCell>

                      <TableCell>
                        <StatusBadges
                          type="transaction"
                          status={row?.status === 1 ? 'pending' : row?.status === 2 ? 'verified' : 'rejected'}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isLoadingMore && <div className="text-center">Loading more...</div>}
        </div>
      )}
      {allTransactions.length === 0 && !transactionsIsLoading && (
        <div className="text-center title-card mt16">
          <h4 data-testid="no-pitches-available">No transactions yet</h4>
        </div>
      )}
      {transactionsIsLoading && isInitialLoad && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
    </div>
  );
}
